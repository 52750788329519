import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind("[data-fancybox]", {
      // Вимкніть автоматичне масштабування при відкритті зображення
      Image: {
        zoom: true, // Включити зумування
        Panzoom: {
          maxScale: 5, // Максимальний коефіцієнт зумування
          minScale: 2, // Мінімальний коефіцієнт зумування
          zoom: true, // Дозволити зумування
        }
      },
      // Клік на контент
      clickContent: function (current, event) {
        if (current.type === "image") {
          // Якщо поточний контент - зображення
          const instance = Fancybox.getInstance();
          const panzoom = instance.Panzoom;

          // Перевірка, чи в даний момент не зумується
          if (panzoom) {
            panzoom.zoomIn(); // Збільшення зуму
          }

          return false; // Зупинка подальших дій
        }
      },
    });
  });
