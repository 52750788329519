document.addEventListener("DOMContentLoaded", function () {
  const modificationButtons = document.querySelectorAll("[id^='modification-button']");
  const clearButton = document.getElementById("clear-button");

  modificationButtons.forEach(button => {
    button.addEventListener("click", function () {
      const modificationId = this.dataset.id;
      const url = new URL(window.location.href);

      url.searchParams.set('product_modifications', modificationId);

      window.location.href = url.toString();
    });
  });

  if (clearButton) {
    clearButton.addEventListener("click", function () {
      const url = new URL(window.location.href);

      url.searchParams.delete('product_modifications');

      window.location.href = url.toString();
    });
  }
});
