document.addEventListener("turbo:load", function(){
  var currentURL = window.location.href;

  if (currentURL.includes("contacts")) {
    var elements = document.querySelectorAll('.contacts__nav-item');
    var innerBlocks = document.querySelectorAll('.inner-block');

    function setActiveTab(index) {
      elements.forEach(function(el) {
        el.classList.remove('active');
      });

      innerBlocks.forEach(function(block) {
        block.style.display = 'none';
      });

      elements[index].classList.add('active');
      innerBlocks[index].style.display = 'block';
    }

    elements.forEach(function(element, index) {
      element.addEventListener('click', function() {
        setActiveTab(index);
      });
    });

    setActiveTab(0);
  }

  if (currentURL.includes("contacts")) {
    var previousURL = document.referrer;

    if (previousURL.includes("brides")) {
      setActiveTab(2);
    }

    document.getElementById('contact_order_files').addEventListener('change', function(event) {
      previewImages(event);
    });

    function previewImages(event) {
      var previewContainer = document.getElementById('preview-container');
      var files = event.target.files;

      if (files.length > 5) {
        alert("Please select no more than 5 photos.");
        event.target.value = '';
        return;
      }

      previewContainer.innerHTML = '';
      previewContainer.style.opacity = files.length > 0 ? 1 : 0;

      var newFiles = [];

      for (var i = 0; i < files.length; i++) {
        var reader = new FileReader();
        var file = files[i];

        reader.onload = (function(index, file) {
          return function(e) {
            var previewBox = document.createElement('div');
            previewBox.className = 'preview-box';

            var previewClose = document.createElement('div');
            previewClose.className = 'preview-close';
            previewClose.addEventListener('click', function() {
              previewContainer.removeChild(previewBox);
              if (previewContainer.children.length === 0) {
                previewContainer.style.opacity = 0;
              }

              var input = document.getElementById('contact_order_files');
              var newFiles = Array.from(input.files);
              var index = newFiles.indexOf(file);
              if (index !== -1) {
                newFiles.splice(index, 1);

                var newDataTransfer = new DataTransfer();
                newFiles.forEach(function(file) {
                  newDataTransfer.items.add(file);
                });

                input.files = newDataTransfer.files;
              }
            });

            var previewImage = document.createElement('img');
            previewImage.className = 'preview-image';
            previewImage.src = e.target.result;

            previewBox.appendChild(previewImage);
            previewBox.appendChild(previewClose);
            previewContainer.appendChild(previewBox);
          };
        })(i, file);

        reader.readAsDataURL(file);
      }
    }
  }

  if (currentURL.includes("account")) {
    var openPopupBtn  = document.querySelector('.submit');
    var popup         = document.querySelector('.confirm-modal');
    var popupBg       = document.querySelector('.popup-bg');
    var closePopup    = document.querySelector('.close-button');
    var closePopupTwo = document.querySelector('.modal-button-close');

    openPopupBtn.addEventListener('click', function() {
      popup.classList.add('active');
      popupBg.classList.add('active');
    })

    popupBg.addEventListener('click', function() {
      close();
    })
    closePopup.addEventListener('click', function() {
      close();
    })
    closePopupTwo.addEventListener('click', function() {
      close();
    })

    function close() {
      popup.classList.remove('active');
      popupBg.classList.remove('active');
    }


    var btnSend = document.querySelector(".send-main-button");
    var infoMessage = document.querySelector(".send__message");

    btnSend.addEventListener("click", function() {
      setTimeout(function() {
          location.reload(); // Перезавантаження сторінки через 2 секунди
          sessionStorage.setItem("addActiveClass", "true"); // Зберігаємо інформацію у sessionStorage
      }, 1000);
      });
        window.addEventListener("load", function() {
          var addActiveClass = sessionStorage.getItem("addActiveClass");
          if (addActiveClass === "true") {
              infoMessage.classList.add("active");
              // Очищаємо sessionStorage після того, як клас "active" був доданий
              sessionStorage.removeItem("addActiveClass");
              // Встановлюємо таймер для видалення класу "active" після 4 секунд
              setTimeout(function() {
                  infoMessage.classList.remove("active");
              }, 4000);
          }
      });
  }
});

