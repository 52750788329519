var currentURL = window.location.href;

if (currentURL.includes("boutiques") || currentURL.includes("become-a-partner")) {
  var bg               = document.querySelector('.popup-bg');
  var modal            = document.querySelector('.map-contacts-modal');
  var closeBtn         = document.querySelector('.close-button');
  var map              = document.getElementById('map');
  var contactsSubtitle = document.querySelector('.contacts-subtitle');
  var contactsTitle    = document.querySelector('.contacts-title');
  const storeNameField = document.querySelector('#contact_order_store_name');
  const orderSubmitBtn = document.querySelector('#submitBtn');
  const massege        = document.querySelector('.send__message');


  function toggleActive() {
    bg.classList.toggle('active');
    modal.classList.toggle('active');
  }

  map.addEventListener('click', function (event) {
    const boutiqueName = event.target.dataset.boutiqueName;
    const boutiqueLocation = event.target.dataset.boutiqueLocation;

    if (event.target.classList.contains('main-button')) {
      contactsSubtitle.textContent = boutiqueLocation;
      contactsTitle.textContent = boutiqueName;
      storeNameField.value = boutiqueName;

      toggleActive();
    }
  });

  closeBtn.addEventListener('click', toggleActive);
  bg.addEventListener('click', toggleActive);
  orderSubmitBtn.addEventListener('click', toggleActive);

  orderSubmitBtn.addEventListener('click', function() {
    setTimeout(function() {
      if (!document.querySelector('#modal').hasChildNodes()) {
        massege.classList.add('active');
        setTimeout(function() {
          massege.classList.remove('active');
        }, 5000);
      }
    }, 3000);
  });

  document.querySelector('.send__message-cls').addEventListener('click', function() {
    massege.classList.remove('active');
  })

  document.addEventListener("DOMContentLoaded", function() {
    let previousValue = '';

    function handleSelectChange() {
      const select2Rendered = document.querySelector('#select2-select-country1-container');
      if (select2Rendered) {
        const currentValue = select2Rendered.textContent.trim();
        if (currentValue !== previousValue) {
          previousValue = currentValue;
          const event = new CustomEvent("change", {
            bubbles: true,
            cancelable: true
          });
          document.querySelector('select[data-action="change->maps#fetchBoutiquesByCountry"]').dispatchEvent(event);
        }
      }
    }

    function observeSelect2Rendered() {
      const select2Rendered = document.querySelector('#select2-select-country1-container');
      if (select2Rendered) {
        const valueObserver = new MutationObserver(function(mutations) {
          mutations.forEach((mutation) => {
            if (mutation.type === 'childList' || mutation.type === 'characterData') {
              handleSelectChange();
            }
          });
        });
        valueObserver.observe(select2Rendered, { childList: true, subtree: true, characterData: true });
        return true;
      }
      return false;
    }

    const observer = new MutationObserver(function(mutationsList, observer) {
      if (observeSelect2Rendered()) {
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
    observeSelect2Rendered();
  });


}
