document.addEventListener('DOMContentLoaded', function () {
  var currentURL = window.location.href;
  if (currentURL.includes('contacts') ||
      currentURL.includes('account') ||
      currentURL.includes('become-a-partner') ||
      currentURL.includes('orders') ||
      currentURL.includes('boutiques') ||
      currentURL.includes('checkout')) {
    initializeIntlTelInput('phone');
  }

  if (currentURL.includes('become-a-partner')) {
    initializeIntlTelInput('phone2');
  }

  if (currentURL.includes('contacts')) {
    initializeIntlTelInput('career_phone');
    initializeIntlTelInput('bride_to_be_phone');
  }
});

function initializeIntlTelInput(elementId) {
  const input = document.getElementById(elementId);
  if (!input) {
    console.error(`Input with id '${elementId}' not found`);
    return;
  }

  const iti = window.intlTelInput(input, {
    initialCountry: 'auto',
    geoIpLookup: callback => {
      fetch('https://ipapi.co/json')
        .then(res => res.json())
        .then(data => callback(data.country_code))
        .catch(() => callback("us"));
    },
    hiddenInput: 'phone_number',
    separateDialCode: true,
    utilsScript: '//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.js'
  });

  iti.promise.then(() => {
    const dropdownContainer = input.closest('.iti');
    if (!dropdownContainer) {
      console.error('Dropdown container not found for', elementId);
      return;
    }

    const dropdownList = dropdownContainer.querySelector('.iti__country-list');
    if (!dropdownList) {
      console.error('Country list not found for', elementId);
      return;
    }

    const searchWrapper = document.createElement('div');
    searchWrapper.className = 'input-search-wrapper';

    const searchInput = document.createElement('input');
    searchInput.type = 'text';

    searchWrapper.appendChild(searchInput);

    dropdownList.insertBefore(searchWrapper, dropdownList.firstChild);

    searchInput.addEventListener('focus', () => {
      document.body.addEventListener('keydown', blockIntlTelInputEvents, true);
    });

    searchInput.addEventListener('blur', () => {
      document.body.removeEventListener('keydown', blockIntlTelInputEvents, true);
    });

    searchInput.addEventListener('mousedown', function (event) {
      event.stopPropagation();
    });

    document.addEventListener('click', function (event) {
      if (event.target === searchInput || searchWrapper.contains(event.target)) {
        event.stopPropagation();
      }
    }, true);

    searchInput.addEventListener('input', function () {
      const searchTerm = searchInput.value.toLowerCase();
      Array.from(dropdownList.querySelectorAll('.iti__country')).forEach(country => {
        const countryName = country.querySelector('.iti__country-name').textContent.toLowerCase();
        const countryCode = country.getAttribute('data-dial-code').toLowerCase();
        if (countryName.includes(searchTerm) || countryCode.includes(searchTerm)) {
          country.style.display = 'block';
        } else {
          country.style.display = 'none';
        }
      });
    });

    dropdownList.addEventListener('click', function (event) {
      if (event.target.classList.contains('iti__country')) {
        dropdownContainer.classList.remove('iti--open');
      }
    });

    function blockIntlTelInputEvents(event) {
      event.stopImmediatePropagation();
    }

    console.log(`Search input with wrapper added to #${elementId}`);
  }).catch(err => {
    console.error('Error initializing intlTelInput for', elementId, err);
  });
}


document.addEventListener('DOMContentLoaded', function () {
  const phoneElement = document.querySelector('#phone');

  if (phoneElement) {
    const phoneWidth = phoneElement.offsetWidth;

    document.querySelectorAll('.iti__country-list').forEach(list => {
      list.style.width = `${phoneWidth}px`;
      list.style.zIndex = "997";
    });
  }
});
