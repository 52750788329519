var currentURL = window.location.href;

if (currentURL.includes("faq")){
  var faqPartner = document.querySelector('.faq-partner')
  var faqUser = document.querySelector('.faq-user')

  faqPartner.addEventListener('click', function() {
    faqPartner.classList.add('active');
    faqUser.classList.remove('active');
  })

  faqUser.addEventListener('click', function() {
    faqUser.classList.add('active');
    faqPartner.classList.remove('active');
  })
}
