import Rails from "@rails/ujs";
import { Turbo } from '@hotwired/turbo-rails';

if (document.querySelector(".select-country") || document.querySelector(".select-size")) {
    $(document).ready(function() {
        $('.select-country').select2();
        $('.select-size').select2();
    });
}

var currentURL = window.location.href;

if (currentURL.includes("/wedding-dresses/") ||
    currentURL.includes("/evening-dresses/") ||
    currentURL.includes("/ky-atelier/")      ||
    currentURL.includes("/in-stock/")        ||
    currentURL.includes("/accessories/")     ||
    currentURL.includes("/lingerie/")) {

    let previousValue = '';

    function handleSelectChange() {
        if (currentURL.includes("/shop/")) {
            var selectElement = document.querySelector('select.select-size[data-controller="products"][data-action="change->products#updateOnlineShopSizeOptions"]');
        } else {
            var selectElement = document.querySelector('select.select-size[data-controller="products"][data-action="change->products#updateSizeOptions"]');
        }
        if (selectElement) {
            const event = new Event("change", {
                bubbles: true,
                cancelable: true
            });
            selectElement.dispatchEvent(event);
        }
    }

    function hideSearchBars() {
        const searchBars = document.querySelectorAll('.select2-search.select2-search--dropdown');
        if (searchBars.length > 0) {
            searchBars.forEach(searchBar => {
                searchBar.style.display = "none";
            });
            return true;
        }
        return false;
    }

    function observeSelect2() {
        const select2Rendered = document.querySelector('.select2-selection__rendered');
        if (select2Rendered) {
            const valueObserver = new MutationObserver(function(mutations) {
                mutations.forEach((mutation) => {
                    if (mutation.type === 'childList' || mutation.type === 'characterData' || mutation.type === 'attributes') {
                        const currentValue = select2Rendered.textContent.trim();
                        if (currentValue !== previousValue) {
                            previousValue = currentValue;
                            handleSelectChange();
                        }
                    }
                });
            });
            valueObserver.observe(select2Rendered, { childList: true, subtree: true, characterData: true });
            return true;
        }
        return false;
    }

    function initializeSelect2() {
        $('select.select-country').select2();
        hideSearchBars();
        observeSelect2();
    }

    const observer = new MutationObserver(function(mutationsList, observer) {
        hideSearchBars();
    });

    observer.observe(document.body, { childList: true, subtree: true });
    initializeSelect2();

    document.addEventListener("turbo:load", initializeSelect2);
}

// if (currentURL.includes("/cart")) {
//     // Створюємо новий MutationObserver
//     const observer = new MutationObserver((mutationsList, observer) => {
//         for (let mutation of mutationsList) {
//             if (mutation.type === 'childList') {
//                 // Шукаємо елемент, якщо він з'явився
//                 const searchBar = document.querySelector('.select2-search.select2-search--dropdown');
//                 if (searchBar) {
//                     searchBar.style.display = "none";
//                     // При бажанні можемо зупинити спостерігача після того, як елемент знайдено
//                     observer.disconnect();
//                 }
//             }
//         }
//     });

//     // Вказуємо, за якими змінами спостерігати
//     const config = { childList: true, subtree: true };

//     // Починаємо спостерігати за всім документом
//     observer.observe(document, config);
// }

if (currentURL.includes("boutiques") || currentURL.includes("become-a-partner")) {
    var observer = new MutationObserver(function(mutationsList, observer) {
        for(var mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                var boutiquesCity = document.querySelectorAll('.select2-container.select2-container--default.select2-container--open');
                boutiquesCity.forEach(function(element) {
                    element.style.zIndex = "1002";
                });
                break;
            }
        }
    });

    observer.observe(document.body, { childList: true, subtree: true });
}

if (currentURL.includes("contacts")         ||
    currentURL.includes("become-a-partner") ||
    currentURL.includes("boutiques")        ||
    currentURL.includes("orders/new")       ||
    currentURL.includes("cart/checkout")) {
    function checkAndHideElement() {
        var select2Options = document.querySelectorAll('.select2-results__option');
        select2Options.forEach(function(option) {
            if (option.textContent.trim() === 'Select country' ||
                option.textContent.trim() === 'Вибрати країну' ||
                option.textContent.trim() === 'Wybierz kraj' ||
                option.textContent.trim() === 'Land auswählen' ||
                option.textContent.trim() === 'Выберите страну')
                {
                option.style.display = 'none';
            }
        });
    }

    function observeDOMChanges() {
        var targetNode = document.body;

        var config = { childList: true, subtree: true, characterData: true };

        var observer = new MutationObserver(function(mutationsList) {
            for(var mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    checkAndHideElement();
                }
                else if (mutation.type === 'characterData') {
                    if (mutation.target.classList.contains('select2-results__option')) {
                        checkAndHideElement();
                    }
                }
            }
        });
        observer.observe(targetNode, config);
    }

    checkAndHideElement();
    observeDOMChanges();
}
