import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["media", "poster"]

    connect() {
      console.log("MediaController connected");
    }

    change(event) {
      const file = event.target.files[0];
      const posterInput = this.posterTarget;

      if (file && (file.type === "video/mp4" || file.type === "video/quicktime")) {
        posterInput.style.display = "block";
        posterInput.required = true;
      } else {
        posterInput.style.display = "none";
        posterInput.required = false;
      }
    }
}
