import { Controller } from "@hotwired/stimulus"
import L from "leaflet"

export default class extends Controller {
  static targets = ["container", "country"]
  static values = { userRole: { type: String, default: 'user' } }

  connect() {
    const container = this.containerTarget;
    this.map = L.map(container).setView([50.4501, 30.5234], 5);
    this.customIcon = L.icon({
      iconUrl: '/assets/marker.svg',
      iconSize: [18, 18],
    });

    var marker = L.marker([51.505, -0.09], { icon: this.customIcon }).addTo(this.map);

    this.map.on('zoomend', () => {
      this.updateIconSize();
    });

    this.fetchBoutiquesByCountry();
    this.element.addEventListener('click', this.handleClick);
  }

  updateIconSize() {
    var currentZoom = this.map.getZoom();

    var iconWidth = 18 + currentZoom * 1;
    var iconHeight = 18 + currentZoom * 1;

    var customIcon = L.icon({
      iconUrl: '/assets/marker.svg',
      iconSize: [iconWidth, iconHeight],
    });

    this.customIcon = customIcon;
    this.updateMarkerIcons();
  }

  updateMarkerIcons() {
    this.map.eachLayer(layer => {
      if (layer instanceof L.Marker) {
        layer.setIcon(this.customIcon);
      }
    });
  }

  fetchBoutiquesByCountry() {
    fetch('/generate-boutique-list')
    .then(response => {
      if (!response.ok) {
        console.error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      this.initializeMap(data);
    })
    .catch(error => {
      console.error('There was a problem with your fetch operation:', error);
    });
  }

  initializeMap(data) {
    const country = this.countryTarget.value

    const filteredData = country ? data.filter(boutique => boutique.country === country) : data;

    if (!!country) {
      this.clearMarkers()
    }

    if (country) {
      const countryCenter = this.calculateCenter(filteredData);
      this.map.setView(countryCenter, 6);
    } else {
      this.map.setView([50.4501, 30.5234], 5);
    }

    var currentURL = window.location.href;
    var contact_text = "Contact"

    if (currentURL.includes("/ua")) {
      contact_text = "Звʼязатись"
    } else if (currentURL.includes("/ru")) {
      contact_text = "Связаться"
    } else if (currentURL.includes("/de")) {
      contact_text = "Kontakt"
    } else if (currentURL.includes("/pl")) {
      contact_text = "Kontakt"
    }

    filteredData.forEach(boutique => {
      this.userRoleValue;
      const marker = L.marker([boutique.latitude, boutique.longitude], { icon: this.customIcon }).addTo(this.map);
      const copyButton = `
        <button data-action="click->maps#copyToClipboard"
                data-maps-address-param="${boutique.address}"
                data-maps-phone-param="${boutique.phone_number}"
                data-maps-email-param="${boutique.email}"
                class="main-button">
          Copy Info
        </button>
      `;

      const popupContent = `
        <b>${boutique.name}</b>
        <p>${boutique.address}</p>
        <a href="tel:${boutique.phone_number}">${boutique.phone_number}</a>
        <button data-action="click->order#handleClick"
                data-boutique-name="${boutique.name}"
                data-boutique-location="${boutique.country}, ${boutique.city}"
                class="main-button">${contact_text}</button>
        ${this.userRoleValue === 'admin' || this.userRoleValue === 'partner' ? copyButton : ''}
      `;

      marker.bindPopup(popupContent);
    });

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      minZoom: 2,
      maxZoom: 15,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);
  }

  copyToClipboard(event) {
    const address = event.params.address;
    const phone = event.params.phone;
    const email = event.params.email;

    const text = `Address: ${address}\nPhone: ${phone}\nEmail: ${email}`;
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }

  calculateCenter(boutiques) {
    const latSum = boutiques.reduce((sum, boutique) => sum + parseFloat(boutique.latitude), 0);
    const lonSum = boutiques.reduce((sum, boutique) => sum + parseFloat(boutique.longitude), 0);

    const latAvg = latSum / boutiques.length;
    const lonAvg = lonSum / boutiques.length;

    return [latAvg, lonAvg];
  }

  clearMarkers() {
    this.map.eachLayer(layer => {
        if (layer instanceof L.Marker) {
            this.map.removeLayer(layer);
        }
    });
  }

  disconnect() {
    if (this.map) {
      this.map.remove();
    }
  }
}
