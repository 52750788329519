document.addEventListener("turbo:load", function(){
  var currentURL = window.location.href;

  if (currentURL.includes("carts")){
    var bg               = document.querySelector('.popup-bg');
    var modal            = document.querySelector('.confirm-modal');
    var openModalBtns    = document.querySelectorAll('.remove-item-button');
    var closeButtons     = document.querySelectorAll('.on-click-close');

    openModalBtns.forEach(function(openModalBtn) {
      openModalBtn.addEventListener('click', function() {
        modal.classList.add("active");
        bg.classList.add("active");
      })
    })

    closeButtons.forEach(function(closeButton) {
      closeButton.addEventListener('click', function() {
        modal.classList.remove("active");
        bg.classList.remove("active");
      })
    })
  }
});
