var currentURL = window.location.href;

if (currentURL.includes("/requests")) {
    const myOrdersItems = document.querySelectorAll('.my-orders-item');

    myOrdersItems.forEach(item => {
        item.addEventListener('click', () => {
        item.classList.toggle('open');
        });
    });
}
