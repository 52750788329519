import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-selector"
export default class extends Controller {
  connect() {
    this.innerItems = document.querySelectorAll('.inner-img');
    this.showImageBox = document.querySelector('.show-image-box');

    if (this.innerItems.length === 0 || !this.showImageBox) {
      console.error("No elements found for image selector");
      return;
    }

    this.innerItems.forEach(innerItem => {
      innerItem.addEventListener('click', () => {
        this.setActive(innerItem);
      });
    });
  }

  setActive(innerItem) {
    if (!innerItem || !this.showImageBox) return;

    this.innerItems.forEach(item => item.classList.remove('active'));

    innerItem.classList.add('active');

    this.showImageBox.innerHTML = '';

    if (innerItem.classList.contains('video-item')) {
      const videoUrl = innerItem.querySelector('img')?.dataset.src;

      if (videoUrl) {
        const video = document.createElement('video');
        video.setAttribute('src', videoUrl);
        video.setAttribute('controls', '');
        video.setAttribute('autoplay', '');
        this.showImageBox.appendChild(video);
      }
    } else {
      const imageUrl = innerItem.querySelector('img')?.getAttribute('src');
      if (imageUrl) {
        const img = document.createElement('img');
        img.setAttribute('src', imageUrl);
        this.showImageBox.appendChild(img);
      }
    }
  }
}
