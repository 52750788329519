import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".handle",
      animation: 150,
      onEnd: this.updateOrder.bind(this),
    });
  }

  updateOrder(event) {
    const itemElements = this.element.querySelectorAll(".sortable-item[data-id]");
    const ids = Array.from(itemElements).map(item => item.dataset.id)

    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ ids: ids }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update the order");
        }
      })
      .catch((error) => {
        console.error("Error updating the order:", error);
      });
  }

  disconnect() {
    this.sortable.destroy();
  }
}
