import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      handle: ".handle",
      onEnd: this.updateOrder.bind(this),
      scroll: true,
      scrollSensitivity: 100,
      scrollSpeed: 10,
    });
  }

  async updateOrder(event) {
    const itemElements = this.element.querySelectorAll(".sortable-item[data-id]");
    const ids = Array.from(itemElements).map((item) => item.dataset.id);

    try {
      const response = await fetch(this.urlValue, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify({ ids }),
      });

      if (!response.ok) {
        throw new Error("Failed to update order");
      }
    } catch (error) {
      console.error("Error updating the order:", error);
    }
  }

  disconnect() {
    this.sortable.destroy();
  }
}
