import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    if (this.isFilterActive()) {
      this.disableDnD();
    } else {
      this.enableDnD();
    }

    this.topSensitivity = 300;
    this.bottomSensitivity = 150;
    this.speed = 20;
    this.scrollInterval = null;
  }

  isFilterActive() {
    const filterParam = new URLSearchParams(window.location.search).get("filter");
    return filterParam !== null && filterParam.trim() !== "";
  }

  disableDnD() {
    this.element.classList.add("dnd-disabled");
    this.element.style.cursor = "not-allowed";
    console.log("DnD is disabled due to active filter.");
  }

  enableDnD() {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      handle: '.product-item',
      onEnd: this.onUpdate.bind(this),
      dataIdAttr: 'data-id',
      scroll: false,
      onMove: this.onMove.bind(this)
    });

    this.topSensitivity = 300;
    this.bottomSensitivity = 150;
    this.speed = 20;

    this.scrollInterval = null;
  }

  onMove(evt) {
    const clientY = evt.originalEvent.clientY;
    const viewportHeight = window.innerHeight;

    clearInterval(this.scrollInterval);

    if (clientY < this.topSensitivity) {
      this.scrollInterval = setInterval(() => {
        window.scrollBy(0, -this.speed);
      }, 50);
    } else if (clientY > viewportHeight - this.bottomSensitivity) {
      this.scrollInterval = setInterval(() => {
        window.scrollBy(0, this.speed);
      }, 50);
    } else {
      clearInterval(this.scrollInterval);
    }
  }

  async onUpdate(event) {
    clearInterval(this.scrollInterval);

    if (this.isFilterActive()) {
      console.log("DnD update blocked due to active filter.");
      return;
    }

    let ids = Array.from(this.element.querySelectorAll(".product-item")).map(
      item => item.getAttribute("data-id")
    );

    let updateUrl = this.element.getAttribute("data-sortable-url-value");
    this.csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    try {
      const response = await fetch(updateUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        body: JSON.stringify({ ids: ids })
      });

      if (response.ok) {
        window.location.reload();
      } else {
        console.error('Failed to update positions:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  disconnect() {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.stopScrolling);
  }
}
