import { Application } from "@hotwired/stimulus"
import { Datepicker } from 'stimulus-datepicker'
import Sortable from "stimulus-sortable"
import SearchItemsController from "./search_items_controller";
import DateRangePickerController from "./date_range_picker_controller"

const application = Application.start();
application.register('datepicker', Datepicker);
application.register('sortable', Sortable);
application.register('search-items', SearchItemsController);
application.register('date-range-picker', DateRangePickerController)

application.locale = 'en';

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
